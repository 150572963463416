import React from 'react'
import Helmet from 'react-helmet'

import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import airnyt from '../assets/images/airnytsmaller.gif'


import acroexplorers from '../assets/images/acroexplorerssmaller.gif'

import oaxacaexploreryes from '../assets/images/oaxacaexploreryessmall.gif'
//import oaxacaexploreryes from '../assets/images/acroeoaxacaexploreryes.gif'

import trychemistrymapyes from '../assets/images/trychemistrymapyessmaller.gif'

import appendToGatsby from '../assets/images/appendToGatsbysmaller.gif'

import yaymusicmap from '../assets/images/yaymusicmapsmaller.gif'

import berlinmusicmapvideo from '../assets/images/bmm.gif'

import acrotags from '../assets/images/acrotags.gif'

import aprinnova from '../assets/images/aprinnova.gif'

const WEB_DEV_ITEMS = [

  {
    id: '1',

    thumbnail: berlinmusicmapvideo,
    caption: 'Berlin Music Map',
    description:
      'I have freelanced remotely from Berlin a couple times and thoroughly enjoyed their music scene. I made this live music map exploration interface using Gatsby, React, React-Window, Google-Maps-React and the Soundcloud API. It is similar to Yay Music Map (a previous project) but features pages for each show and venue (while the media player keeps playing as the pages are changed). It is incredibly fast thanks to Gatsby.',
    external_link: 'https://www.berlinmusicmap.com',
  },
  {
    id: '2',

    thumbnail: aprinnova,
    caption: 'Aprinnova Website Redo',
    description:
      'Constructed a new Wordpress website for Aprinnova with user logins, PDF downloads, restricted content access, and Salesforce-connected forms with custom fields.',
    external_link: 'http://www.aprinnova.com',
  },
  {
    id: '3',

    thumbnail: airnyt,
    caption: 'AirNYT',
    description:
      'This was an AirBnb clone I built with React/Material-UI/FireBase for a tutorial series. It allows users to explore eight years of New York Times Travel Recommendations.',
    external_link: 'http://airnyt.surge.sh/',
  },
  {
    id: '5',

    thumbnail: acrotags,
    caption: 'AcroTags',
    description:
      'This simple AcroYoga video discovery app was made for a tutorial I wrote for Gatsby.',
    external_link: 'https://acroexplorers.com',
  },
  {
    id: '5',

    thumbnail: oaxacaexploreryes,
    caption: 'OaxacaExplorer',
    description:
      "I mapped 95% of Oaxaca's Biking and Hiking Routes and made a site dedicated to helping people explore the outdoors in this Mexican state. The digital map is made with React-leaflet and the paper map was made with QGIS.",
    external_link: 'http://oaxacaexplorer.com',
  },
  {
    id: '6',

    thumbnail: trychemistrymapyes,
    caption: 'Chemistry Cannabis Dispensary Location Map',
    description:
      'Map (using Google Sheets as a CMS) that I built for cannabis company, Chemistry. Features Google Places Auto-Complete and allows for filtering based off the map edges (the list updates according to dragging the map).',
    external_link: 'https://www.trychemistry.com/find',
  },
  {
    id: '7',

    thumbnail: yaymusicmap,
    caption: 'Yay Music Map',
    description:
      'I scraped over 100 music venue calendars in four cities, combined the artist data with Spotify + SoundCloud, mapped it all with React and Google Maps and made a pretty dang cool live music discovery tool! Lesson learned: Focus more on the sustainable data scraping vs. the interface. The constant manual data cleaning burned me out. [Dummy Data / Defunct] ',
    external_link: 'https://yaymusicmap.com/location/sfbay',
  },
  {
    id: '8',

    thumbnail: appendToGatsby,
    caption: 'appendTo with Gatsby and NetlifyCMS',
    description:
      "This demo is nothing too impressive itself, but it's built upon Gatsby (blazing fast) and NetlifyCMS (allows non-technical users to update the content). I'm really excited about the JAMStack",
    external_link: 'https://appendtostaticstyled.netlify.com/about',
  },

]


class HomeIndex extends React.Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const siteTitle = "Kyle Pennell's Portfolio and Personal Site"
    const siteDescription =
      'All About Kyle Pennell and his professional experience.'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>
                Hello 👋. I'm Kyle Pennell.
              </h2>
              <h3>
              I am a Solutions Engineer focused on geospatial analyis and visualization. I currently work at CARTO.
              </h3>
              <h3>
             I need to update this site, please see my <a href="https://www.linkedin.com/in/kpennell/">Linkedin page</a> for a more updated version of my work experience. 
              </h3>
      
              <br />
            </header>
            <h4>Content Creation + Marketing Experience</h4>
            <p>
              My ~5 years of content experience has been primarily focused on
              creating SEO content to educate buyers and generate
              leads. Here are a couple top-level highlights from content marketing efforts of which I’ve been a part:
            </p>
            <ul>

        
              <li>
                Created the new{' '}
                <a href="https://aprinnova.com/" target="_blank">
                  Aprinnova
                </a>{' '}
                website and helped them increase traffic 4x over 8 months.
              </li>
              <li>
                Created dozens of pieces of renewables-related content (and backlinks via guestposts) which grew{' '}
                <a href="https://powerscout.com/" target="_blank">
                  PowerScout
                </a>{' '}
                traffic 500% and Domain Authority from 37 to 40 in 5 months.
              </li>
              <li>
                Grew <a href="https://appendto.com/">appendTo</a> traffic 800%
                over 10 months with a wide variety of content and tutorials on
                web development.
              </li>
            </ul>
            <ul className="actions">
              <li>
                <a href="#contentportfolio" className="button">
                  Content Portfolio ↓
                </a>
              </li>
            </ul>

            <h4>Web Development Experience</h4>
            <p>
              I develop websites and webapps. The goal of many of my projects is to help people get
              outside, see live music, and connect with other people. I’m a big
              believer is using the web to get off the web. 
                
                <br />
                In 2019, I build most sites and apps using React, Firebase,{' '}
              <a href="https://www.gatsbyjs.org/">Gatsby</a>, Wordpress (with
              the <a href="https://avada.theme-fusion.com/">Avada</a> theme),
              the Google Sheets API and Material-UI. I also enjoy
              teaching other people how to make web apps and have written
              several dozen tutorials on various programming topics. You can
              explore my web dev projects below:
            </p>
            <ul className="actions">
              <li>
                <a href="#webdevportfolio" className="button">
                  Web Dev Portfolio ↓
                </a>
              </li>
            </ul>
          </section>

          <section id="contentportfolio">

            <h2>Content Portfolio</h2>
            <div>

            <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org/blog/2019-07-23-google-sheets-gatsby-acroyoga-video-explorer/">
                  <span>
                  Using the Google Sheets API With Gatsby for Creating Fast Sites With Simple Data Sources
             
                  </span>
                </a>
              </h4>
              <p>
                <span>
                Here's a tutorial I wrote for Gatsby's blog on how to use Google Sheets with Gatsby.
                </span>
              </p>
            <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://appendto.com/2018/10/airnyt-airbnb-nyt-clone/">
                  <span>
                    AirNYT: Clone Airbnb with New York Times Travel Data
                  </span>
                </a>
              </h4>
              <p>
                <span>
                  This was a mega-tutorial series I wrote on building an AirBnb
                  clone using React, Material-UI, and Firebase. As you can see
                  in portfolio, I love building map products (and want to make
                  it easier for others to do the same).
                </span>
              </p>

              <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://dev.to/kpennell/i-made-an-adventure-map-for-oaxaca-mexico-and-here-s-what-i-learned-3io5">
                  <span>
                  I made an adventure map for Oaxaca, Mexico and here's what I learned
                  </span>
                </a>
              </h4>
              <p>
                <span>
                  Here's a writeup on Dev.to about what I learned while making OaxacaExplorer.
                </span>
              </p>
            <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://www.sitepoint.com/es6-arrow-functions-new-fat-concise-syntax-javascript/">
                  <span>
                    ES6 Arrow Functions: Fat and Concise Syntax in JavaScript
                  </span>
                </a>
              </h4>
              <p>
                <span>
                  It gives me great joy that this post consistently ranks #1 or
                  #2 for &lsquo;fat arrow&rsquo;. This article was one of
                  several technical posts I wrote for SitePoint (it later got
                  included in an ebook). I think it encapsulates my writing
                  style of knowing how to balance the nitty-gritty details with
                  the more big picture perspective. Based off the comments and
                  how much it gets linked to, it seems people quite liked it.
                </span>
              </p>

              <h4>
                <a target="_blank" rel="noopener noreferrer" href="http://web.archive.org/web/20190105041508/https://powerscout.com/home-solar/solar-panel-costs">
                  <span>
                    2018 Solar Panel Costs Guide (written for PowerScout, which
                    has pivoted to zesty.ai)
                  </span>
                </a>
              </h4>

              <p>
                <span>
                  This solar panel cost guide was written to specifically target
                  the most important &lsquo;high-intent&rsquo; solar SEO
                  keywords around cost and pricing. Enriching the content and
                  building links did seem to help boost it&rsquo;s SEO ranking 7
                  places but we couldn&rsquo;t quite get to page 1.
                </span>
              </p>

              <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://appendto.com/2019/04/build-fast-and-elegant-sites-with-gatsby-netlifycms-and-material-ui/">
                  <span>
                    Build Fast and Elegant Sites with Gatsby, NetlifyCMS and
                    Material-UI
                  </span>
                </a>
              </h4>
              <p>
                <span>
                  This is a two-part tutorial series I wrote on how to build
                  really fast sites using Gatsby, NetlifyCMS, and
                  Material-UI. I tried to simplify the process of learning these
                  tools as much as I could.
                </span>
              </p>

              <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://www.developintelligence.com/blog/devops-for-non-technical-people/">
                  <span>DevOps Simplified for Non-Technical People</span>
                </a>
              </h4>
              <p>
                <span>
                  This is a large ebook-sized guide I wrote and edited about the
                  DevOps technology ecosystem in 2016. I hired and managed five writers to
                  help with the project.
                </span>
              </p>

              <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://www.developintelligence.com/blog/2015/09/front-end-development-explained-for-non-developers/">
                  <span>
                    Front-End Development Explained for Non-Developers
                  </span>
                </a>
              </h4>
              <p>
                <span>
                  This was a blog series I wrote to make web development
                  technologies easy to understand for non-technical people. Be
                  it that most{' '}
                </span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.developintelligence.com/">
                  <span>DevelopIntelligence</span>
                </a>
                <span>
                  {' '}
                  client contacts are non-technical people in charge of
                  technical training, one of our jobs was constantly helping them understand the technologies their developers were learning.
                </span>
              </p>

              <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://www.developeracademy.io/developer-learning-survey-report/">
                  <span>
                    DevelopIntelligence Developer Learning Survey Report
                  </span>
                </a>
              </h4>
              <p>
                <span>
                  This learning report is key white paper DevelopIntelligence uses for lead
                  generation and during the sales process. It helps our clients
                  understand what, how, and why software developers want to
                  learn (and receive training).
                </span>
              </p>
              <p>
                <span>
                  For this report, we included or own data as well as several
                  other industry leaders&rsquo; survey reports.
                </span>
              </p>

              

              <h4>
                <a target="_blank" rel="noopener noreferrer" href="https://blog.udemy.com/learn-to-learn-better-all-posts/">
                  <span>Learn to learn, better </span>
                </a>
              </h4>
              <p>
                <span>
                  This was an article series I wrote at Udemy (a while back now)
                  that focuses on the meta skills around learning. I find this
                  such a fascinating topic and am constantly working on my own
                  learning skills.
                </span>
              </p>

             
            </div>
          </section>

          <section id="webdevportfolio">
            <h2>Web Development Portfolio</h2>

            <Gallery
              images={WEB_DEV_ITEMS.map(
                ({ id, thumbnail, caption, description, external_link }) => ({
                  thumbnail,
                  caption,
                  description,
                  external_link,
                })
              )}
            />
          </section>

          <section id="contactsection">
            <h2>Get In Touch</h2>

            <div className="row">
              <div>
                <ul className="labeled-icons">
                  <li>
                    <h3 className="icon fa-envelope-o">
                      <span className="label">Email</span>
                    </h3>
                    kpennell at gmail dot com
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
