import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Img from "gatsby-image"

class Gallery extends Component {
  constructor() {
    super()

    this.state = {}
  }

  renderGallery() {
    const { images } = this.props

    if (!images) return

    const gallery = images.map((obj, i) => {
      return (
        <article className="6u 12u$(xsmall) work-item" key={i}>
             <a
            
            href={obj.external_link ? obj.external_link : ''}
            target="_blank"
          >
            <img src={obj.thumbnail} className="image fit " />
            
        
     
          <h3>{obj.caption}</h3>
          </a>
          <p>{obj.description}</p>
         
        </article>
      )
    })

    return <div className="row">{gallery}</div>
  }
  render() {
    return <div>{this.renderGallery()}</div>
  }
}

Gallery.displayName = 'Gallery'
Gallery.propTypes = {
  images: PropTypes.array,
}

export default Gallery
